<template>
  <span class="label filter" :class="{active: selectedFilters.includes(filter.id)}" @click="$emit('selectFilter', filter.id)">{{ filter.emoji }} {{ filter.title }}</span>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Filter",
  props: {
    filter: JSON,
    selectedFilters: Array
  },
  emits: ['selectFilter'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style scoped>
span.filter{
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 1em;
  font-family: 'Helvetica';
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
  background-color: #000000!important;
}
span.filter.active{
  background-color: #009de0!important;
}
</style>