export const questions = [
    {
        question:'<h4 class="t-center f-bold pad-8">Wozu hast du Fragen?</h4>',
        answers: []
    },
    {
        question:'<span class="label t-center">🎟️ Tickets</span>',
        answers:[
            {
                question:'<h4 class="t-center f-bold pad-8">Fragen zu Tickets</h4>',
            },
            {
                question:'<span class="label">🎫 SIND DIE TICKETS ÜBERTRAGBAR?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Nein, die Tickets sind nicht übertragbar und Personengebunden.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ KANN ICH MEINE TICKETS ZURÜCK GEBEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Nein, Tickets können nicht zurückgegeben werden, wir bitten um Verständnis.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ GIBT ES EINE ABENDKASSE?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Sofern wir im Vorverkauf noch nicht ausverkauft sind, gibt es die restlichen Tickets direkt an der Abendkasse zu kaufen. Diese hat ab Veranstaltungsbeginn geöffnet & befindet sich im Kassenbereich der Location.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">✅ WIE LÖSE ICH MEIN TICKET EIN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">' +
                            'Unsere <b>digitalen</b> Tickets kannst du bequem über dein Smartphone vorzeigen. Bitte zeig an der Kasse den QR "rangezoomt" vor.' +
                            '<br><br>' +
                            ' <b>Harttickets</b> (welche du zB ggf. an Vorverkaufsstellen kaufen kannst) - musst du bitte immer im Originalen mitbringen. Hier reicht kein Foto oder ähnliches.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ WAS BEDEUTET KEINE 100% EINLASSGARANTIE?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Für jeden Ticketinhaber haben wir einen Platz "vorgesehen". Wenn diese Person aber stark alkoholisiert ist oder vor Ort negativ auffällt (benimmt sich schlecht), erhält diese Person trotz Ticket keinen Einlass.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ WAS IST, WENN DIE VERANSTALTUNG AUSFÄLLT / VERSCHOBEN WIRD?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Sollte eine Veranstaltung ausfallen oder verschoben werden, so ist dein Ticket automatisch für das nächste Event gültig.' +
                            '<br><br>' +
                            'Wenn du stattdessen lieber dein Geld für das Ticket zurück haben möchtest, so schreib uns eine E-Mail an kontakt@smea.info mit deinen Bestell- und Bankdaten.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎟️ MEIN TICKET KAM NICHT AN / ICH HABE FALSCHE DATEN ANGEGEBEN</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Schick uns dazu einfach eine E-Mail an info@stadtmuehle.club mit deinen Bestelldaten und wohin wir dein Ticket verschicken sollen.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">✅ WIE LÖSE ICH MEIN TICKET EIN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Unsere <b>digitalen</b> Tickets kannst du bequem über dein Smartphone vorzeigen. Bitte zeig an der Kasse den QR-Code "rangezoomt" vor.' +
                            '<br><br>' +
                            ' <b>Harttickets</b> (welche du z.B. ,ggf., an Vorverkaufsstellen kaufen kannst) - musst du bitte immer im Original mitbringen. Hier reicht kein Foto oder ähnliches.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">💳 MIT WELCHEN ZAHLUNGSMITTELN KANN ICH DAS TICKET BEZAHLEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Wir akzeptieren Paypal, Klarna und Sofortüberweisung.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">ℹ️ Allgemein</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Allgemeine Fragen</h4>',
            },
            {
                question:'<span class="label">🧥 GARDEROBE</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Bei allen unseren Veranstaltungen gibt es eine Garderobe. Diese kostet 2€ je Kleidungsstück. Manche Garderoben haben auch Kapazitäten um Taschen abzugeben. Wichtig: Die Kapazitäten können aufgebraucht sein, wenn du zu spät zur Veranstaltung kommst. Wenn du genauere Infos haben möchtest, so bitten wir dich direkt die Location zu kontaktieren.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ FUNDSACHEN</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Solltest du etwas verloren haben, so kontaktiere bitte direkt die Location. Alle Sachen die wir finden oder bei uns abgegeben werden, rufen wir direkt am Abend aus oder geben es an der Location ab.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🧥 DRESSCODE</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Wir empfehlen ein sauberes Outfit und ein gepflegtes Äußeres. Wir leben von den Gästen die im Club feiern, nicht vor dem Club, also mach dir nicht zu viele Sorgen :)</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">⏰ WANN BEGINNT / ENDET DIE VERANSTALTUNG?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Die genauen Öffnungszeiten findest du auf unseren digitalen Veranstaltungs-Ankündigungen und auf dem Ticket. In der Regel beginnen unsere Veranstaltungen um 22:00 Uhr und enden um 05:00 Uhr morgens.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🪪 WIE KANN ICH MICH AUSWEISEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Wir akzeptieren: Personalausweis / Reisepass. In Ausnahmefällen akzeptiert das Sicherheitspersonal auch den Führerschein. Bilder von Ausweisen oder Ähnliches werden nicht akzeptiert.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🚗 GIBT ES GENÜGEND PARKPLÄTZE VOR ORT?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">In der Regel gibt es genügend Parkplätze in umliegender Nähe.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">ℹ️ DARF ICH EIGENE SPEISEN & GETRÄNKE MITNEHMEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Speisen & Getränke dürfen leider nicht mitgebracht werden.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🚪 KANN ICH DIE VERANSTALTUNG KURZ VERLASSEN & WIEDER REIN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">In der Regel ist dies nicht möglich.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">💳 KANN ICH VOR ORT MIT KARTE ZAHLEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Ja dies ist möglich und wird auch bevorzugt.</span></div>',
                        answers:[]
                    }
                ]
            },

            {
                question:'<span class="label">ℹ️ AN WEM WENDE ICH MICH BEI FRAGEN VOR ORT?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Wende dich an das Servicepersonal.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">🔞 U18</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Fragen zu U18</h4>',
            },
            {
                question:'<span class="label">👶 KANN ICH AUCH MIT UNTER 16 JAHREN MITFEIERN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Der Einlass auf unseren Veranstaltungen (bei 16+ Veranstaltungen) ist ab 16 Jahren. Ausnahmen werden hier nicht gemacht.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🔞 WIE VIELE U18 KANN EIN VOLLJÄHRIGER GAST BEAUFSICHTIGEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Eine Volljährige Begleitperson darf 3 Personen beaufsichtigen.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">📝 WOHER ERHALTE ICH DEN MUTTIZETTEL?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Diesen findest du bei uns in der App oder verlinkt auf unserer Homepage. Unseren digitalen Muttizettel brauchst du nicht ausdrucken, sondern kannst diesen bequem digital auf deinen Handy vorzeigen.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🍷 DARF ICH ALS MINDERJÄHRIGER GAST ALKOHOL TRINKEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Nur die Spirituosen, die in Deutschland ab 16 Jahren frei erwerblich sind.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">⏰ ICH BIN FRÜHER ALS MEINE BEGLEITUNG DA, DARF ICH SCHON REIN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Die Veranstaltung musst du gemeinsam mit deiner Aufsichtsperson betreten.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🤔 MUSS ICH ALS MINDERJÄHRIGER DIE PARTY MIT MEINER BEGLEITUNG GEMEINSAM VERLASSEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Ja</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🍷 DARF ICH ALS MINDERJÄHRIGER GAST ALKOHOL TRINKEN?</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Nur die Spirituosen, die in Deutschland ab 16 Jahren frei erwerblich sind.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">🎵 Musik</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Fragen zur Musik</h4>',
            },
            {
                question:'<span class="label">🎧 DJs</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Welche DJs legen auf?' +
                            '<br><br>' +
                            'Bei unseren Veranstaltungen legen die unterschiedlichsten "Tour-DJs" aus dem europäischen Raum auf. Auf unserer Website findest du ein paar Eindrücke unserer DJs. Auf den jeweiligen Partyaccounts kündigen wir den DJ für jede Veranstaltung an.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎼 MUSIK</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Welche Musik wird gespielt?' +
                            '<br><br>' +
                            'Welche Musik gespielt wird, erfährst du über unsere Instagram-Posts und Newsletter.</span></div>',
                        answers:[]
                    }
                ]
            },
            {
                question:'<span class="label">🎹 LIEDER-WÜNSCHE</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Schreibe deinen Wunschsong gerne lesbar auf einem Zettel & frag höflich den DJ ob er diesen annimmt.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">💪 Jobs</span>',
        answers:[
            {
                question:'<h4 class="t-center pad-8 f-bold">Fragen zu Jobs</h4>',
            },
            {
                question:'<span class="label">💪 ICH WÜRDE GERNE FÜR EUCH ARBEITEN</span>',
                answers:[
                    {
                        question:'<div><span class="label blue">Dann füll doch jetzt das Kontaktformular in unserer App oder auf unserer Homepage aus.</span></div>',
                        answers:[]
                    }
                ]
            },
        ]
    },
    {
        question:'<span class="label t-center">❔ Sonstiges</span>',
        answers:[
            {
                question:'<span class="label blue">Bitte schreibe uns eine Nachricht mit deiner Frage in dem untenstehenden Nachrichtenfeld.</span>',
            },
        ]
    },
]