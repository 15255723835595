<template>
  <div>
    <div style="padding-top: 100px; padding-bottom: 120px;">
      <section v-if="page === 'oldOrders'">
        <div style="max-width: 500px; margin: 0 auto; margin-top: 16px; padding: 16px;">
          <button @click="page = 'overview';" class="button bgcolor-black color-white fluid"> Zurück zur Übersicht </button>
        </div>
        <div id="headerTitle" class="pad-16">
          <h1>Vergangende Bestellungen</h1>
          <h2>Übersicht deiner getätigten Bestellungen</h2>
          <div v-if="!auth.isAuth">
            <h3 style="margin-top: 40px; font-size: 1.1em; text-align: center;">
              Bitte logge dich ein um deine Bestellungen einsehen zu können
            </h3>
            <div class="pad-16 t-center">
              <div style="max-width: 500px; margin-top: 16px;" >
                <router-link to="/login" class="button bgcolor-blue color-white"> Zum Login </router-link>
              </div>
            </div>
          </div>
          <table v-else style="max-width: 500px; margin: 0 auto; margin-top: 32px; width: 100%;">
            <thead class="bgcolor-second">
              <tr class="f-bold">
                <td>ID</td>
                <td>Betrag</td>
                <td>Rechnung</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order) in orders" :key="order.id">
                <td style="padding: 8px 0;">{{ order.id }}</td>
                <td class="t-right" style="padding-right: 16px;">{{ order.total }}€</td>
                <td class="t-center">
                  <span v-if="order.paymentMethod === 'BarPay'">Barzahlung</span>
                  <download-p-d-f v-if="order.invoice" :link="order.invoice.encrypted" name="Rechnung" target="" file-type="invoice" class="color-white" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section v-if="page === 'myOrders'">
        <div id="headerTitle" class="pad-16">
          <h1 v-if="config.pickUpNumber">{{ config.pickUpNumber }}</h1>
          <h1 v-else>{{pickUpNumber}}</h1>
          <h2 v-if="pickUpNumber || config.pickUpNumber">Bitte zeige diesen Code an der {{barTitle}} Bar vor, um deine Bestellung abzuholen, sobald diese bereit ist.</h2>
          <h3 style="margin-top: 40px; font-size: 1.2em; text-align: center;">
            <span v-if="pickUpStatus === 'open'">Deine Bestellung befindet sich aktuell noch in der Zubereitung</span>
            <span v-if="pickUpStatus === 'ready'" style="color: rgb(175, 255, 0);">Deine Bestellung kann nun abgeholt werden</span>
            <span v-if="pickUpStatus === 'fulfilled'">Deine Bestellung wurde abgeholt<br><b style="font-size: 2em;">🍹 Prost!</b></span>
          </h3>
          <div style="max-width: 500px; margin: 0 auto; margin-top: 16px; padding: 16px;">
            <button @click="page = 'overview';" class="button bgcolor-black color-white fluid"> Zurück zur Übersicht </button>
          </div>
        </div>
      </section>
      <div v-if="page === 'overview'">
        <section v-if="!barId">
          <div style="max-width: 500px; margin: 0 auto; margin-top: 16px; padding: 16px;">
            <button @click="page = 'oldOrders';" class="button bgcolor-black color-white fluid"> Deine Bestellungen </button>
          </div>
          <div id="headerTitle">
            <h1>Unsere Bars 🍹</h1>
            <h2>Bitte wähle eine Bar aus, um eine <br>Getränkebestellung aufgeben zu können</h2>
          </div>
          <div v-if="pickUpNumber || config.pickUpNumber" style="max-width: 500px; margin: 0 auto; margin-top: 16px; padding: 16px;">
            <button @click="page = 'myOrders';" class="button bgcolor-black color-white fluid"> Mein Abholcode </button>
          </div>
          <div style="max-width: 500px; margin: 0 auto; margin-top: 32px;">
            <article class="event" v-for="bar in bars" :key="bar.id" @click="barId = bar.id; barImage = bar.image; barMenuId = bar.barMenuId; barTitle = bar.title; barDescription = bar.description; barPayment = bar.payment;">
              <div class="eventBox">
                <img class="eventImage" :src="config.projectURL + 'assets/images/lounges/'+bar.image+'.jpg'">
                <div class="imageCover" />
                <div class="eventWrapper">
                  <div class="grid-12">
                    <div class="col-sp-12 row-sp-12 pad-16">
                      <h4>{{ bar.title }}</h4>
                      <h5>{{ bar.description }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>
        <section v-else>
          <div style="max-width: 500px; margin: 0 auto; margin-bottom: 16px;">
            <button @click="barId = ''; bucket = []" class="button bgcolor-black color-white fluid"> Zurück zur Übersicht </button>
          </div>
          <div id="headerTitle">
            <h1>{{ barTitle }}</h1>
            <h2>{{ barDescription }}</h2>
          </div>
          <div style="max-width: 500px; margin: 0 auto; margin-top: 32px;">
            <h4 style="font-size: 1.4em; margin-left: 16px;">{{items[0].BarMenuName}}</h4>
            <p style="font-size: 1em; margin-left: 16px; color: #ffffff90">{{items[0].menuDescription}}</p>
          </div>
          <div style="max-width: 500px; margin: 0 auto; margin-top: 8px;">
            <div class="pad-16">
              <div v-for="category in categories" :key="category.barCategoryId" class="orderOverview">
                <h1 style="font-weight: bold; margin: 20px 0 12px; font-size: 1.2em">
                  {{ category.categoryName }}
                </h1>
                <div v-for="item in items.filter(i => i.barCategoryId === category.barCategoryId).sort((a,b) => a.listOrder - b.listOrder)" :key="item.id" class="itemOrder">
                  <div class="grid-12">
                    <div class="col-sp-4 row-sp-12">
                      <div class="itemName">
                        <p>{{ item.name }} [{{item.amount}}{{item.unit}}]<br><span style="font-size: 10px;">{{item.ingredients}}</span></p>
                      </div>
                    </div>
                    <div class="col-sp-5 row-sp-12">
                      <div class="itemDescription">
                        <p>{{ item.caption }}</p>
                      </div>
                    </div>
                    <div class="col-sp-2 row-sp-12">
                      <div class="itemPrice">
                        <p>{{ item.price.toFixed(2) }}€</p>
                      </div>
                    </div>
                    <div class="col-sp-1 row-sp-12">
                      <div class="itemAdd" @click="addToBucket(item); splash($event)">
                        <span style="font-weight: 600; display: block">+</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-16">
              <h4>Inhaltsstoffe:</h4>
              <p style="color: #ffffff75">{{items[0].ingredientsList}}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <transition name="bucket" v-if="barId && page !== 'myOrders'">
      <div class="order" :style="{'height': bucketShow ? '75%' : '39px'}" v-if="bucketInit">
        <div class="orderName" @click="bucketShow = !bucketShow">
          Warenkorb {{ price.netto }}€ ({{ bucket.map(item => item.quantity).reduce((prev, next) => prev + next, 0) }})
          <img class="orderShow" src="/assets/images/icons/arrow-left.svg" :style="'transform: rotate(' + (bucketShow ? '-' : '+') + '90deg)'">
        </div>
        <div class="orderList">
          <div class="header">
            <transition name="login" mode="out-in">
              <div v-if="auth.isAuth" class="loggedInAs">
                Eingeloggt als:
                {{ user.firstname }} {{ user.lastname }}
              </div>
              <div v-else>
                <div class="flex checkoutAs-Wrapper">
                  <div v-if="orderAs === 'user'" @click="orderAs = 'guest'" class="button">
                    Als Gast bestellen
                  </div>
                  <div v-if="orderAs === 'guest'" @click="orderAs = 'user'" class="button-primary">
                    Mit Account anmelden
                  </div>
                </div>
                <transition name="login" mode="out-in">
                  <div v-if="orderAs === 'guest'" class="checkoutAsGuest-wrapper flex" style="justify-content: center;">
                    <input type="text" v-model="guestData.firstname" placeholder="Vorname">
                    <input type="text" v-model="guestData.lastname" placeholder="Nachname">
                    <input type="email" v-model="guestData.email" placeholder="E-Mail" @focusout="validateEmail()">
                    <p class="t-center color-red">
                      {{ errormessage }}
                    </p>
                  </div>
                  <div v-else>
                    <div v-if="!loginIn" class="checkoutLogin-wrapper flex" style="justify-content: center;">
                      <input type="email" v-model="loginData.email" placeholder="E-Mail">
                      <input type="password" v-model="loginData.password" placeholder="Passwort" @keydown.enter="logIn">
                      <p class="t-center color-red">
                        {{ errormessage }}
                      </p>
                      <button type="button" @click="logIn" class="button buttonLogin">
                        Einloggen
                      </button>
                    </div>
                    <loader size="small" v-else />
                  </div>
                </transition>
              </div>
            </transition>
          </div>

          <div v-if="bucket.length">
            <div v-for="category in orderCategories" :key="category.barCategoryId">
              <h1 style="font-weight: bold; padding-bottom: 6px;">
                {{ category.categoryName }}
              </h1>
              <hr>
              <div v-for="item in bucket.filter(i => i.barCategoryId === category.barCategoryId).sort((a,b) => a.listOrder - b.listOrder)" :key="item.id" class="itemOrder">
                <div class="itemNamePriceQuantity" style="display: flex; column-gap: 100%; flex-wrap: wrap; justify-content: left">
                  <div class="itemName">
                    {{ item.name }}
                  </div>
                  <div style="display: flex; column-gap: 8px;">
                    <div class="itemQuantity">
                      {{ item.quantity }}x
                    </div>
                    <div class="itemSinglePrice">
                      {{ item.price.toFixed(2) }}€
                    </div>
                  </div>
                </div>
                <div class="itemPrice">
                  {{ (item.price * item.quantity).toFixed(2) }}€
                </div>
                <div class="itemDelete" @click="removeFromBucket(item.id)">
                  <span style="font-weight: 600; display: block">-</span>
                </div>
              </div>
            </div>
            <hr class="separator">
            <coupon :items="couponItems" @coupon="c => coupon = c" />
            <hr class="separator">
            <h2 style="font-size: 1.2em; padding-bottom: 8px;">
              Möchtest du trinkgeld geben?
            </h2>
            <input class="individualTipInput" type="number" step="0.1" v-model="tip">€
            <div class="individualTip-wrapper">
              <button @click="tip = 0" :class="{active: tip === 0}">
                0%
              </button>
              <button @click="tip = Math.round(price.netto * 5) / 100" :class="{active: tip === Math.round(price.netto * 5) / 100}">
                5%
              </button>
              <button @click="tip = Math.round(price.netto * 10) / 100" :class="{active: tip === Math.round(price.netto * 10) / 100}">
                10%
              </button>
            </div>
            <hr class="separator">

            <payment-type :amount="price.total" @total="credits => {useCredits = credits}" />
            <hr class="separator">
            <div v-if="readyState">
              <button v-if="(barPayment === 1 || barPayment === 3)" @click="orderAppPay" class="button-primary" style="width: 100%;">
                Für {{ (price.total - useCredits/100).toFixed(2) }}€ Bestellen & per App zahlen
              </button>
              <div v-if="barPayment === 1" class="t-center pad-8" style="color: #ffffff90;">oder</div>
              <button v-if="(barPayment === 1 || barPayment === 2)" @click="orderBarPay" class="button bgcolor-black color-white fluid" style="width: 100%;">
                Für {{ (price.total - useCredits/100).toFixed(2) }}€  Bestellen & Bar bezahlen
              </button>
            </div>
            <div v-else>
              <p class="t-center color-white">Bitte logge dich ein, oder fülle die obigen Felder (Email, Vorname, Nachname) aus um mit der Bestellung fortzufahren</p>
            </div>
            <div style="margin-bottom: 80px"></div>
          </div>
          <div v-else>
            Bitte füge Produkte deiner Bestellung hinzu
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {aSyncData} from "@/modules/main";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import paymentType from "@/components/payment/paymentType";
import coupon from "@/components/payment/coupon";
import DownloadPDF from "@/components/payment/downloadPDF";


export default {
  name: "barOrderView",
  title: "Getränke Bestellen",
  components: {
    paymentType,
    coupon,
    DownloadPDF
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      page: 'overview',
      barId: '',
      barMenuId: '',
      barImage: '',
      barTitle: '',
      barDescription: '',
      items: [],
      bars: [],
      bucketInit: false,
      bucketShow: false,
      bucket: [],
      coupon: {},
      useCredits: 0,
      tip: 0,
      pickUpNumber: '',
      pickUpStatus: 'open',
      interval: null,
      errormessage: '',
      barPayment: 1,
      guestData: {
        firstname: '',
        lastname: '',
        email: '',
      },
      loginData: {},
      loginIn: false,
      orderAs: 'user',
      orders: []

    }
  },
  computed: {
    readyState(){
      return this.auth.isAuth || (this.guestData.firstname.length && this.guestData.lastname.length && this.guestData.email.length)
    },
    categories(){
      return [...new Map(this.items.map(item => [item['barCategoryId'], item])).values()].sort((a,b) => {
        if(a.categoryOrder < b.categoryOrder){
          return -1
        }else if(a.categoryOrder > b.categoryOrder){
          return 1
        }
        return a.barCategoryId <= b.barCategoryId
      })
    },
    orderCategories(){
      return [...new Map(this.bucket.map(item => [item['barCategoryId'], item])).values()].sort((a,b) => {
        if(a.categoryOrder < b.categoryOrder){
          return -1
        }else if(a.categoryOrder > b.categoryOrder){
          return 1
        }
        return a.barCategoryId <= b.barCategoryId
      })
    },
    couponItems(){
      const drinks = {}
      this.bucket.forEach(b => {
        drinks[b.id] = b
      })
      return {drinks: drinks}
    },
    price(){
      const price = {}
      price.subTotal = ((Math.round(this.bucket.map(item => item.quantity * item.price).reduce((prev, next) => prev + next, 0)*100))/100)
      price.discount = 0

      if(this.coupon.discount){
        price.discount = this.coupon.discount
      }

      price.discount = price.discount.toFixed(2)
      price.netto = (Math.round(Math.max(0, price.subTotal - price.discount) * 100) / 100)
      price.total = (Math.round((Math.max(0, price.subTotal - price.discount) + this.tip) * 100)/100)
      console.log(price, price.subTotal + this.tip)
      return price
    }
  },
  watch: {
    barMenuId(){
      this.loadBarMenu()
    },
  },
  mounted() {
    this.getOrders()
    this.getBars()
    setTimeout(() => {this.bucketInit = true}, 500)
  },
  beforeUnmount() {
    this.bucketInit = false
  },
  created() {
    this.checkForPickupCode();
  },
  methods: {
    checkForPickupCode() {
      const pickupcode = this.getQueryParam("pickupcode");

      if (pickupcode) {
        this.bucket = []
        this.pickUpStatus = 'open'
        this.barId = ''
        this.pickUpNumber = pickupcode
        this.config.pickUpNumber = pickupcode
        this.page = 'myOrders'
        this.bucketShow = false
        this.interval = setInterval(() => this.checkPickupStatus(), 5*1000)
      }
    },
    getQueryParam(name) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    },
    getBars(){
      aSyncData('bars/getAll')
          .then(r => {
            if(r.status === 250) this.bars = r.data
          })
          .catch(e => {console.error(e)})
    },
    getOrders(){
      if(this.user){
        aSyncData('bars/getOrders')
            .then(r => {
              console.log(r)
              if(r.status === 250) this.orders = r.data
            })
            .catch(e => {console.error(e)})
      }
    },
    checkPickupStatus(){
      let pickUpNumber
      if(this.pickUpNumber){pickUpNumber = this.pickUpNumber}else{pickUpNumber = this.config.pickUpNumber}
      aSyncData('bars/checkPickupStatus', {pickUpNumber: pickUpNumber})
          .then(r => {
            console.log(r.data.status)
            if(r.status === 250){
              this.pickUpStatus = r.data.status
              if(r.data.status === 'fulfilled'){
                this.config.pickUpNumber = ''
                this.pickUpNumber = ''
                clearInterval(this.interval)
              }
            }
          })
          .catch(e => {console.error(e)})
    },
    loadBarMenu(){
      aSyncData('bars/getBarMenu', {id: this.barMenuId})
          .then(r => {
            console.log(r.data)
            if(r.status === 250) this.items = r.data
          })
          .catch(e => {console.error(e)})
    },
    addToBucket(item){
      let temp = this.bucket.find(b => b.id === item.id)
      if(temp){
        temp.quantity++
      }else{
        item.quantity = 1
        this.bucket.push(item)
      }
    },
    removeFromBucket(itemId){
      try {
        let item = this.bucket.find(b => b.id === itemId)
        if(item.quantity > 1){
          item.quantity--
        }else{
          this.bucket = this.bucket.filter(b => b.id !== itemId)
        }
      }catch (e) {console.error(e)}
    },
    orderAppPay(){
      this.loading = true
      this.error = ''
      aSyncData('checkout/barorder', {
        firstname: this.guestData.firstname,
        lastname: this.guestData.lastname,
        email: this.guestData.email,
        barId: this.barId,
        couponId: this.coupon.id ?? null,
        currentSite: this.$route.path,
        items: {bardrinks: this.bucket, tip: [{price: this.tip}]},
        subTotal: this.price.subTotal + this.tip,
        total: this.price.total,
        useCredits: this.useCredits,
        payPal: this.price.total - this.useCredits/100
      })
          .then(r => {
            if(r.status === 250) {
              if(r.data.link) {
                location.href = r.data.link
              }else if(r.data.cipher){
                //this.$router.push({name: 'Checkout', params: {cipher: r.data.cipher}})
                this.bucket = []
                this.pickUpStatus = 'open'
                this.barId = ''
                this.pickUpNumber = r.data
                this.config.pickUpNumber = r.data
                this.page = 'myOrders'
                this.bucketShow = false
                this.interval = setInterval(() => this.checkPickupStatus(), 5*1000)
              }
            }else{
              //TODO noch auf deutsch übersetzen
              this.error = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },
    orderBarPay(){
      this.loading = true
      this.error = ''
      aSyncData('bars/addBarOrder', {
        barId: this.barId,
        items: {drinks: this.bucket, tip: [{price: this.tip}]},
        subTotal: this.price.subTotal + this.tip,
        total: this.price.total,
        paymentMethod: 'BarPay',
        checkoutSessionId: null,
        meta: null,
      })
          .then(r => {
            console.log(r.data)
            if(r.status === 250) {
              this.bucket = []
              this.pickUpStatus = 'open'
              this.barId = ''
              this.pickUpNumber = r.data
              this.config.pickUpNumber = r.data
              this.page = 'myOrders'
              this.bucketShow = false
              this.interval = setInterval(() => this.checkPickupStatus(), 5*1000)

            }else{
              //TODO noch auf deutsch übersetzen
              this.error = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },

    splash(e){
      e.target.parentNode.parentNode.classList.add('splash')
      setTimeout(() => {
        e.target.parentNode.parentNode.classList.remove('splash')
      },250)
    },

    closeParentModal(){
      this.$emit('close')
    },
    logIn(){
      this.loginIn = true
      this.auth.login(this.loginData.email, this.loginData.password, false)
          .catch(e => {})
          .finally(() => {
            this.loginIn = false
          })
    },
    validateEmail(){
      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(this.guestData.email)){
        this.errormessage = '';
      }else{
        this.errormessage = 'Bitte gebe eine gültige E-Mail-Adresse an.';
      }
    }
  }
}
</script>

<style scoped>
#headerTitle h1{
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 2.2em;
}
#headerTitle h2{
  font-family: 'Helvetica';
  font-weight: 100;
  text-align: center;
  font-size: 1em;
}
.orderOverview .itemOrder{
  margin: 6px 0;
  align-items: center;
  background-color: var(--second-bg-color);
  padding: 12px;
  border-radius: 10px;
}
.orderOverview .itemOrder .itemName{
  flex: 2;
}

.orderOverview .itemOrder .itemDescription{
  flex: 0.8;
}

.orderOverview .itemOrder .itemPrice{
  flex: 1;
}

.orderOverview .itemOrder .itemAdd{
  background-color: #009de0;
  display: flex;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 4px;
}

.order{
  position: fixed;
  bottom: 90px;
  text-align: center;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.75s ease;
}

.order .itemOrder{
  display: flex;
  flex-wrap: wrap;
  margin: 6px 0;
  align-items: center;
}

.order .itemNamePriceQuantity{
  flex: 2;
}

.order .itemPrice{
  flex: 1;
}

.order .itemName {
  text-align: left;
}

.order .itemDelete{
  background-color: #1E1E1E;
  display: flex;
  justify-content: center;
  padding: 4px 6px;
  border-radius: 4px;
  width: 12px;
}

.orderName{
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: black;
  padding: 24px 10px;
}
.orderList{
  background-color: var(--main-bg-color);
  padding: 10px;
  height: 100%;
  overflow: auto;
  border-left: 1px solid white;
  border-right: 1px solid white;
}
img.orderShow{
  height: 20px;
  filter: brightness(0%);
  float: right;
  transition: all 0.75s ease;
}
.separator{
  opacity: 0.1;
  margin: 16px 0;
}

.individualTip-wrapper button{
  margin: 8px 4px 0;
  padding: 12px;
  border-radius: 10px;
  background-color: #1E1E1E;
  border: none;
  color: white;
  width: 80px;
}

.individualTipInput {
  padding: 8px;
  width: 40%;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background-color: #1E1E1E;
  color: white;
}

.lounge-header{

}

.lounge-header h2{
  font-size: 2em;
  font-weight: 300;
}

.loungeSelect{
  background-color: var(--second-bg-color);
  color: white;
  border: none;
  padding: 12px;
  border-radius: 10px;
  width: 100%;
}
.splash{
  animation: splash 0.25s normal forwards ease-in-out;
}
@keyframes splash {
  from {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  to {
    transform: scale(1, 1);
  }
}

.bucket-enter-active{
  transition: all 0.5s ease-out;
}
.bucket-enter-from,
.bucket-leave-to {
  opacity: 0;
}
.event{
  position: relative;
  margin-bottom: 32px;
  cursor: pointer;
}
.event .event-top{
  margin-bottom: -16px;
  position: relative;
  z-index: 9;
  clear: both;
}
.event .event-top .label{
  padding: 10px 8px;
  text-transform: none;
  margin-right: 16px;
}
.event .event-top .promotion{
  font-size: 0.8em;
  color: rgba(255,255,255,0.6);
  font-family: 'Helvetica';
  padding-left: 4px;
}

.event .eventBox{
  background-color: #1E1E1E;
  border-radius: 10px;
}

.event .eventImage{
  max-width: 100%;
  width: 100%;
  border-radius: 10px;
}
.event .eventWrapper{
  max-width: 100%;
  z-index: 4;
  padding: 8px 4px;
}
.event .eventWrapper .eventDate{
  background: #009de0;
  padding: 24px 0;
  margin: 4px;
  border-radius: 10px;
}
.event .eventWrapper .eventDate h5{
  color: #ffffff !important;
  font-weight: 600;
  font-size: 1.1em;
  padding: unset !important;
}

.event .eventDate h6{
  color: #ffffff8a;
  padding-top: 4px;
  font-size: 18px;
}

.event .eventWrapper h4{
  font-size: 1.5em;
  font-family: 'Helvetica';
  font-weight: 600;
  color: white;
  margin-bottom: 0px;
  padding-bottom: 6px;
  margin-top: 10px;
}
.event .eventWrapper h5{
  font-size: 1em;
  font-family: 'Helvetica';
  font-weight: 300;
  color: white;
  padding-bottom: 6px;
}

.checkoutType{
  overflow-y: scroll;
  max-height: 85vh;
}

.login-enter-active,
.login-leave-active {
  transition: all 0.2s ease;
}

.login-enter-from{
  opacity: 0;
  transform: translateX(200px);
}
.login-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}

.checkoutAs-Wrapper{
  flex-wrap: wrap;
  column-gap: 100%;
  justify-content: center;
  row-gap: 10px;
}

.checkoutAs-Wrapper .button-primary, .checkoutAs-Wrapper .button{
  width: 60%;
}

.checkoutAsGuest-wrapper, .checkoutLogin-wrapper{
  flex-wrap: wrap;
  column-gap: 100%;
  row-gap: 10px;
  margin: 24px 0;
}

.checkoutAsGuest-wrapper input, .checkoutLogin-wrapper input{
  padding: 8px;
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background-color: #1E1E1E;
  color: white;
}



.loggedInAs{
  opacity: 0.5;
  font-size: 0.9em;
}

.header{
  padding-top: 20px;
  background-color: var(--main-bg-color);
  margin-bottom: -32px;
  padding-bottom: 32px;
}
</style>