<template>
  <!--<div v-if="notificationItems.promotion == 1">
    <div class="promotionTag">
      <p>Gesponsert</p>
    </div>
    <article class="notification promotion grid-12">
      <div class="col-sp-2 row-sp-12 imageBox">
        <img :src="'assets/images/icons/'+notificationItems.image" class="width-32">
      </div>
      <div class="col-sp-10 row-sp-12 textBox">
        <p v-html="notificationItems.content" />
      </div>
    </article>
  </div>
  <div v-else>-->
  <article class="notification grid-12" :class="{unread: notification.unread}">
    <div class="col-sp-12 row-sp-12 textBox" @click="notifications.openLink(notification.link)">
      <p class="f-bold">
        {{ notification.title }}
      </p>
      <p>{{ notification.body }}</p>
      <p class="t-right color-lightgrey" style="font-size: 0.8em; opacity: 0.4;">
        {{ dateString }}
      </p>
    </div>
  </article>
  <!-- </div> -->
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {notificationStore} from "@/store/notificationStore";
import {dateToString, stringToDate} from "@/modules/main";

export default {
  name: "Notification",
  props: ["notification"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const notifications = notificationStore()
    const user = userStore()

    return {auth, config, notifications, user}
  },
  data() {
    return {
    }
  },
  computed: {
    dateString(){
      return dateToString(stringToDate(this.notification.date))
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style>
.notification{
  position: relative;
  margin-bottom: 16px;
  background-color: var(--second-bg-color);
  color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  border: 2px solid var(--second-bg-color);
}
.unread{
  border: 2px solid #009de0;
}
</style>