<template>
  <div style="padding-top: 50px;"></div>
  <a v-if="config.ad" :href="config.ad.href" target="_blank" id="Ad">
    <img :src="config.projectURL + 'assets/images/ads/'+config.ad.img" alt="Werbung" style="width: 100%;">
  </a>
  <footer @click="supportModal = false;">
    <ul>
      <li>
        <router-link to="/home">
          <img src="assets/images/icons/calendar-white.svg">
          <span>Events</span>
        </router-link>
      </li>
      <li>
        <router-link to="/tickets">
          <img src="assets/images/icons/ticket-icon.svg">
          <span>Tickets</span>
        </router-link>
      </li>
      <li class="middle">
        <router-link to="/wallet">
          <img src="assets/images/icons/charge-white.svg">
        </router-link>
      </li>
      <li v-if="user.activeLounges.length">
        <router-link to="/loungeOrder">
          <img src="assets/images/icons/couch.svg">
          <span>Lounge</span>
        </router-link>
      </li>
      <li v-else>
        <router-link to="/media">
          <img src="assets/images/icons/images-white.svg">
          <span>Fotos</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profile">
          <img v-if="!auth.isAuth" src="assets/images/users/default.svg" class="image circular width-40">
          <img v-else :src="config.projectURL + 'assets/images/users/' + user.avatar" class="image width-40 circular">
          <span>Profil</span>
        </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {questions} from "@/data/questions";

export default {
  name: "Footer",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped>
footer{
  position: fixed;
  bottom: 0;
  z-index: 60;
  width: 100%;
  min-height: 48px;
  padding: 8px 0 max(8px, calc(0.75 * env(safe-area-inset-bottom))) 0;
  background-color: var(--second-bg-color);
}
footer ul{
  padding: 0;
  margin: 0;
  width: 100%;
}
footer ul li{
  width: 20%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  text-align: center;
}
footer ul li a{
  display: block;
  color: rgba(255,255,255,0.8);
  text-align: center;
}
footer ul li a img{
  width: 24px;
  display: block;
  margin: 0 auto;
}
footer ul li a span{
  width: 24px;
  display: inline;
  font-size: 0.8em;
  margin: 0 auto;
}
footer ul li.middle{
  position: relative;
  margin-top: -40px;
}
footer ul li.middle img{
  background-color: #009de0;
  border-radius: 90%;
  width: 48px;
  height: 48px;
  margin-top: 0px;
  padding: 4px;
}
#Ad{
  width: 320px;
  height: 50px;
  background: #ffffff10;
  border: 1px solid #ffffff;
  position: fixed;
  bottom: 65px;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
}
</style>